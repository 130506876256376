function Competencies() {
    return (
        <section className="about-content">
            <h3>Competencies</h3>
            <section>
                <article className="competencies">
                    <h5>01 Leadership and Goal Setting</h5>
                    <p>Led design projects by setting goals and guiding the team to come up with consolidated results</p>
                </article>
                <article className="competencies">
                    <h5>02 Adaptive Workflow Management</h5>
                    <p>Delivered results by adjusting workflow to meet changing requirements in different scenarios</p>
                </article>
                <article className="competencies">
                    <h5>03 Quality Improvement Through Testing</h5>
                    <p>Implemented complex design and development improvements, while focusing on quality through testing and reviewing</p>
                </article>
                <article className="competencies">
                    <h5>04 Stakeholder Communication</h5>
                    <p>Presented ideas, solutions, and results directly to stakeholders, incorporating feedback for improvement and refinement</p>
                </article>
                <article className="competencies">
                    <h5>05 Relationship Building</h5>
                    <p>Built trust and strong relationships with previous employers, team members and clients, shown by repeat business and referrals</p>
                </article>
            </section>
        </section>
    );
};

export default Competencies;