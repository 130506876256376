import Sparkle from "../../assets/icons/sparkle.svg";

import Work1 from "../../assets/images/works/jihan-duerme-featured-works-dsa.png";
import Work2 from "../../assets/images/works/jihan-duerme-featured-works-rkp.png";
import Work3 from "../../assets/images/works/jihan-duerme-featured-works-mh.png";
import Work4 from "../../assets/images/works/jihan-duerme-featured-works-ra.png";
import Work5 from "../../assets/images/works/jihan-duerme-featured-works-cd.png";
import Work6 from "../../assets/images/works/jihan-duerme-featured-works-eco.png";

function Work() {
    return (
        <section className="featured-work">
            {/* Section Title */}
            <header className="d-flex mb-4 mb-md-5">
                <img src={Sparkle} alt="Sparkle" />
                <h2>Featured Work</h2>
            </header>

            <section className="work-cards">
                {/* Work 1 */}
                <a href="https://dsarch.com.au/" target="_blank">
                    <article>
                        <div className="work-card-details">
                            <h3>DS Architects</h3>
                            <p>Connecting with new audiences in the education, private and public sectors.</p>
                        </div>
                        <img src={Work1} alt="DS Architects Website" />
                    </article>
                </a>

                {/* Work 2 */}
                <a href="https://rk.partners/" target="_blank">
                    <article>
                        <div className="work-card-details">
                            <h3>R&K Partners</h3>
                            <p>Providing insight and context into a tax credit consulting firm and their expertise.</p>
                        </div>
                        <img src={Work2} alt="R&K Partners Website" />
                    </article>
                </a>

                {/* Work 3 */}
                <a href="https://meganhounslow.com/" target="_blank">
                    <article>
                        <div className="work-card-details">
                            <h3>Megan Hounslow</h3>
                            <p>Website brand repositioning of an interior designer, reflecting her calm and luxurious style.</p>
                        </div>
                        <img src={Work3} alt="Megan Hounslow Website" />
                    </article>
                </a>

                {/* Work 4 */}
                <a href="https://rarchitecture.com.au/" target="_blank">
                    <article>
                        <div className="work-card-details">
                            <h3>R Architecture</h3>
                            <p>Showcasing various projects contributed to their community in the last 10 years.</p>
                        </div>
                        <img src={Work4} alt="R Architecture Website" />
                    </article>
                </a>

                {/* Work 5 */}
                <a href="https://changingdigital.com/" target="_blank">
                    <article>
                        <div className="work-card-details">
                            <h3>Changing Digital</h3>
                            <p>Creating a new digital home for a tech agency, showcasing their processes and services.</p>
                        </div>
                        <img src={Work5} alt="Changing Digital Website" />
                    </article>
                </a>

                {/* Work 6 */}
                <a href="https://ecotracker.io/" target="_blank">
                    <article>
                        <div className="work-card-details">
                            <h3>Ecotracker</h3>
                            <p>Providing businesses with insights into their environmental impact and customer behavior.</p>
                        </div>
                        <img src={Work6} alt="Ecotracker Website" />
                    </article>
                </a>
            </section>
        </section>
    );
};

export default Work;