// dependencies
import { useEffect, useState } from "react";

import Rich from "../../assets/images/recommendation-rich-freeman.jpg"
import Scott from "../../assets/images/recommendation-scott-mccrindle.jpg"

function Recommendation() {
    let [currentIndex, setCurrentIndex] = useState(0);
    let [contents, setContents] = useState([
        {
            quote: `"Jihan is an extremely talented individual, combining extensive design experience with solid front and back-end web development skills. She's thoughtful and diligent, while also displaying outstanding collaboration skills. Jihan is bright, engaging, and consistently produces high-quality work. I recommend her without reservation as a welcome addition to any team."`,
            citation: "Rich Freeman",
            link: "https://ca.linkedin.com/in/rich-freeman",
            position: "Professor at Georgian College",
            picture: Rich
        },
        {
            quote: `"It is awesome to watch Jihan combine her natural creative and technical ability with her impressive knowledge of various Web technology stacks. Her vibrant personality and her unwavering commitment to quality and innovation will make her an indispensable team member. Jihan is an all-star!"`,
            citation: "Scott McCrindle",
            link: "https://ca.linkedin.com/in/scottmccrindle",
            position: "Professor at Georgian College",
            picture: Scott
        }
    ]);

    useEffect(() => {
        let carousel = document.querySelector('.reco-carousel');
        if (carousel) {
            let carouselContents = carousel.querySelectorAll('.carousel-content');
            if (carouselContents.length > 0) {
                setContents(Array.from(carouselContents).map(content => ({
                    quote: content.querySelector('p').innerText,
                    citation: content.querySelector('figcaption a').innerText,
                    link: content.querySelector('figcaption a').getAttribute('href'),
                    position: content.querySelector('figcaption p:last-of-type').innerText,
                    picture: content.querySelector('figure img').getAttribute('src')
                })));
            }
        }
    }, []);

    let handlePrevClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + contents.length) % contents.length);
    };

    let handleNextClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % contents.length);
    };

    return (
        <section className="about-content">
            <h3>Recommendations</h3>
            <div className="reco-carousel">
                <div className="carousel-container">
                    {contents.map((content, index) => (
                        <div className={`carousel-content ${index === currentIndex ? 'active-content' : ''}`} key={index}>
                            <blockquote>
                                <p>{content.quote}</p>
                                <figure>
                                <a href={content.link}><img src={content.picture} alt={`${content.citation}'s picture`} /></a>
                                    <figcaption>
                                        <a href={content.link}>{content.citation}</a>
                                        <p>{content.position}</p>
                                    </figcaption>
                                </figure>
                            </blockquote>
                        </div>
                    ))}
                </div>
                <div className="carousel-arrows">
                    <button type="button" className="arrow arrow-prev" onClick={handlePrevClick}>
                        <i className="bi bi-arrow-left"></i>
                    </button>
                    <button type="button" className="arrow arrow-next" onClick={handleNextClick}>
                        <i className="bi bi-arrow-right"></i>
                    </button>
                </div>
            </div>
        </section>
    );
};

export default Recommendation;