// dependencies
import { useContext, useEffect } from 'react';
import { ThemeContext } from '../../ThemeContext';

// assets
import ReactIcon from "../../assets/icons/tech-icons-sm/react-icon.png";
import LightIconDesktop from "../../assets/icons/light-icon-desktop.svg";
import DarkIcon from "../../assets/icons/dark-icon.svg";
import Sparkle from "../../assets/icons/sparkle.svg";
import SparkleDark from "../../assets/icons/sparkle-dark.svg";

function Footer() {
    const { darkTheme, toggleTheme } = useContext(ThemeContext);

    useEffect(() => {
        const sparkleImages = document.querySelectorAll('img[alt="Sparkle"]');
        if (darkTheme) {
            sparkleImages.forEach(img => img.src = SparkleDark);
        } else {
            sparkleImages.forEach(img => img.src = Sparkle);
        }
    }, [darkTheme]);

    return (
        <footer>
            <div>
                <p>Design and development by Jihan, using 
                    <img src={ReactIcon} alt="React Icon" />
                </p>
            </div>
            <div>
                <a href="mailto:jihan.duerme@gmail.com">jihan.duerme@gmail.com</a>
                <div>
                    <a href="https://www.linkedin.com/in/jihanduerme/" target="_blank" rel="noopener noreferrer">
                        <i className="bi bi-linkedin"></i>
                    </a>
                    {/* <img
                        src={darkTheme ? DarkIcon : LightIconDesktop}
                        className="theme-icon"
                        onClick={toggleTheme}
                        alt="Theme Icon"
                    /> */}
                </div>
            </div>
        </footer>
    );
}

export default Footer;