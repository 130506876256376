// dependencies
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../ThemeContext';

// assets
import JDLogo from "../../assets/images/jihan-duerme-logo.svg";
import JDLogoDark from "../../assets/images/jihan-duerme-logo-dark.svg";
import LightIconDesktop from "../../assets/icons/light-icon-desktop.svg";
import LightIconMobile from "../../assets/icons/light-icon-mobile.svg";
import DarkIcon from "../../assets/icons/dark-icon.svg";
import Sparkle from "../../assets/icons/sparkle.svg";
import SparkleDark from "../../assets/icons/sparkle-dark.svg";

function Header() {
    let [mobileMenu, setMobileMenu] = useState(false);
    let [scrollProgress, setScrollProgress] = useState(0);
    const { darkTheme, toggleTheme } = useContext(ThemeContext);

    useEffect(() => {
        let scrollEffects = () => {
            let scroll = window.scrollY;
            let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            let scrolled = (scroll / height * 100);
            setScrollProgress(scrolled);

            let nav = document.querySelector("nav");
            if (scroll > 100) {
                nav.classList.add('scrolled');
            } else {
                nav.classList.remove('scrolled');
            }
        };

        scrollEffects();

        window.addEventListener('scroll', scrollEffects);

        return () => {
            window.removeEventListener('scroll', scrollEffects);
        };
    }, []);

    useEffect(() => {
        let sparkleImages = document.querySelectorAll('img[alt="Sparkle"]');
        if (darkTheme) {
            sparkleImages.forEach(img => img.src = SparkleDark);
        } else {
            sparkleImages.forEach(img => img.src = Sparkle);
        }
    }, [darkTheme]);

    let toggleMenu = () => {
        setMobileMenu(prevState => !prevState);
    };

    return (
        <header className="fixed-nav">
            <nav>
                {/* progress bar */}
                <div className="page-bar-container">
                    <div className="page-bar" style={{ width: `${scrollProgress}%` }}></div>
                </div>
                <div className="d-flex">
                    {/* logo */}
                    <Link to="/" className="logo">
                        <img src={darkTheme ? JDLogoDark : JDLogo} alt="Jihan Duerme Logo" />
                    </Link>

                    {/* all links */}
                    <section className='nav-links'>
                        <div id="desktop-links">
                            <Link className="menu-link" to="/">Work</Link>
                            <Link className="menu-link" to="/about">About</Link>
                            <a className="menu-link" href="mailto:jihan.duerme@gmail.com" target="_blank">Contact</a>
                            <img src={darkTheme ? DarkIcon : LightIconDesktop} className="theme-icon" onClick={toggleTheme} alt="Theme Icon" />
                        </div>
                        <img src={darkTheme ? DarkIcon : LightIconDesktop} className="theme-icon-mobile" onClick={toggleTheme} alt="Theme Icon" />
                        <div id="mobile-links" className={mobileMenu ? 'show' : ''}>
                            <Link className="menu-link" to="/">Work</Link>
                            <Link className="menu-link" to="/about">About</Link>
                            <a className="menu-link" href="mailto:jihan.duerme@gmail.com" target="_blank" onClick={toggleMenu}>Contact</a>
                            <div className="mt-2 mb-5">
                                <a href="https://www.linkedin.com/in/jihanduerme/" target="_blank" rel="noopener noreferrer">
                                    <i className="bi bi-linkedin"></i>
                                </a>
                                <img src={darkTheme ? DarkIcon : LightIconMobile} className="theme-icon" onClick={toggleTheme} alt="Theme Icon"
                                />
                            </div>
                        </div>

                        {/* toggle */}
                        <button className={`mobile-menu ${mobileMenu ? 'menu-open' : ''}`} onClick={toggleMenu}>
                            <i className="bi bi-list"></i>
                        </button>
                    </section>
                </div>
            </nav>
        </header>
    );
};

export default Header;
