// assets
import Polaroid1 from "../../assets/images/polaroid-home.png";
import Polaroid2 from "../../assets/images/polaroid-me.png";
import PolaroidMobile from "../../assets/images/polaroids.png";

function CTA() {
    return (
        <section className="about-cta">
            <a className="cta-link" href="/about">About Me</a>
            <img className="mobile-polaroids" src={PolaroidMobile} alt="Boracay" />
            <div className="polaroids">
                <figure>
                    <a href="/about">
                        <img src={Polaroid1} alt="Boracay" />
                        <figcaption>Boracay at sunset and my family</figcaption>
                    </a>
                </figure>
                <figure>
                    <a href="/about">
                        <img src={Polaroid2} alt="Jihan" />
                        <figcaption>Hello, I'm Jihan!</figcaption>
                    </a>
                </figure>
            </div>
        </section>
    );
};

export default CTA;