function Skills() {
    return(
        <section className="about-content">
        <h3>Skills & Technologies</h3>
        <section className="technologies">
            <div>
                <p>JavaScript</p>
                <p>React</p>
                <p>Angular</p>
                <p>jQuery</p>
                <p>HTML5</p>
                <p>CSS3</p>
                <p>SASS/SCSS</p>
                <p>Bootstrap</p>
                <p>PHP</p>
                <p>Node.js</p>
                <p>Express</p>
                <p>ASP.NET</p>
            </div>
            <div>
                <p>MySQL</p>
                <p>MongoDB</p>
                <p>WordPress</p>
                <p>GitHub</p>
                <p>VS Code</p>
                <p>W3C Standards</p>
                <p>SEO Standards</p>
                <p>Design Systems</p>
                <p>WCAG 2.1</p>
                <p>Web Optimization</p>
                <p>Responsive Design</p>
                <p>Usability Heuristics</p>
            </div>
            <div>
                <p>Figma</p>
                <p>Illustrator</p>
                <p>Photoshop</p>
                <p>XD</p>
                <p>Sketch</p>
                <p>Graphic Design</p>
                <p>Illustration</p>
                <p>Google Analytics</p>
                <p>MailChimp</p>
                <p>Digital Marketing</p>
                <p>Project Management</p>
                <p>Agile Frameworks</p>
            </div>
        </section>
        <section className="technologies-mobile">
            <div>
                <p>JavaScript</p>
                <p>React</p>
                <p>Angular</p>
                <p>jQuery</p>
                <p>HTML5</p>
                <p>CSS3</p>
                <p>SASS/SCSS</p>
                <p>Bootstrap</p>
                <p>PHP</p>
                <p>Node.js</p>
                <p>Express</p>
                <p>ASP.NET</p>
                <p>MySQL</p>
                <p>MongoDB</p>
                <p>WordPress</p>
                <p>GitHub</p>
                <p>VS Code</p>
                <p>W3C Standards</p>
            </div>
            <div>
                <p>Figma</p>
                <p>Illustrator</p>
                <p>Photoshop</p>
                <p>XD</p>
                <p>Sketch</p>
                <p>Graphic Design</p>
                <p>Illustration</p>
                <p>SEO Standards</p>
                <p>Design Systems</p>
                <p>WCAG 2.1</p>
                <p>Web Optimization</p>
                <p>Responsive Design</p>
                <p>Usability Heuristics</p>
                <p>Google Analytics</p>
                <p>MailChimp</p>
                <p>Digital Marketing</p>
                <p>Project Management</p>
                <p>Agile Frameworks</p>
            </div>
        </section>
    </section>
    );
};

export default Skills;