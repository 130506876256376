function Experience() {
    return (
        <section className="about-content">
            <h3 className="mt-3">Experience</h3>
            <section className="experience">
                <div className="experience-details align-items-start">
                    <div className="content-name">
                        <p className="m-0">Changing Digital</p>
                        <p className="m-0">UI/UX Designer</p>
                    </div>
                    <p className="content-year"><small>2020-2024</small></p>
                </div>
                <div className="experience-details align-items-start">
                    <div className="content-name">
                        <p className="mb-0">Gambit Technologies</p>
                        <p className="mb-0">Art Director & UI/UX Designer</p>
                    </div>
                    <p className="content-year"><small>2015-2020</small></p>
                </div>
                <div className="experience-details align-items-start">
                    <div className="content-name">
                        <p className="mb-0">Hinge Inquirer Publications</p>
                        <p className="mb-0">Graphic Designer</p>
                    </div>
                    <p className="content-year"><small>2012-2015</small></p>
                </div>
                <div className="experience-details align-items-start">
                    <div className="content-name">
                        <p className="mb-0">The Tshirt Project</p>
                        <p className="mb-0">Graphic Designer</p>
                    </div>
                    <p className="content-year"><small>2010-2011</small></p>
                </div>
            </section>
        </section>
    );
};

export default Experience;