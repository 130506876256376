// shared components
import Header from "./views/shared/Header";
import Footer from "./views/shared/Footer";

// page components
import Home from "./views/Home";
import About from "./views/About";

// dependencies
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <div>
      <Header />
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </div>
      <Footer />
      <div id="blur-bg">
        <div className="blur"></div>
        <div className="blur"></div>
        <div className="blur"></div>
      </div>
    </div>
  );
}

export default App;