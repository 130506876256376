// dependencies
import { useContext, useEffect } from 'react';
import { ThemeContext } from '../ThemeContext';

// assets
import Sparkle from "../assets/icons/sparkle.svg";
import SparkleDark from "../assets/icons/sparkle-dark.svg";
import AboutPhoto from "../assets/images/jihan.png"

// components
import Skills from "./about/Skills";
import Competencies from "./about/Competencies";
import Recommendation from "./about/Recommendation";
import Experience from "./about/Experience";
import Education from "./about/Education";

function About() {
    const { darkTheme } = useContext(ThemeContext);

    useEffect(() => {
        const sparkleImages = document.querySelectorAll('img[alt="Sparkle"]');
        if (darkTheme) {
            sparkleImages.forEach(img => img.src = SparkleDark);
        } else {
            sparkleImages.forEach(img => img.src = Sparkle);
        }
    }, [darkTheme]);

    useEffect(() => {
        document.title = "About | Jihan Duerme - UI/UX Design & Web Development";
    }, []);

    return (
        <main>
            {/* Hero Banner */}
            <section className="about-hero">
                <article>
                    <div className="d-flex mb-4">
                        <img src={Sparkle} alt="Sparkle" />
                        <h2>About</h2>
                    </div>
                    <h1 className="mb-3">I design to bring out the best in brands I work with and share it with the world</h1>
                    <p>I’m Jihan Duerme, a UI/UX designer & web developer. I graduated from the Interactive Media Design Web program at Georgian College in Barrie, Ontario, and am looking to join a team dedicated to creating well-crafted products that blend aesthetics and functionality.</p>
                    <p>With a Bachelor's degree in advertising arts and academic training in computer programming, I bring a multidisciplinary approach to my work. Prior to my studies, I worked with startups to mid-size companies, where I practiced design-driven problem solving. My experience includes designing and developing websites, mobile apps, and marketing products, both independently and as part of a team, from inception to maintenance.</p>
                </article>
                <img src={AboutPhoto} alt="Jihan Duerme" />
            </section>
            <Skills />
            <Competencies />
            <Recommendation />
            <Experience />
            <Education />
            {/* Contact */}
            <section className="about-content about-email">
                <h3>Contact</h3>
                <div>
                    <a href="mailto:jihan.duerme@gmail.com" target="_blank">jihan.duerme@gmail.com</a>
                </div>
            </section>
            <section className="mobile-contact">
                <a href="mailto:jihan.duerme@gmail.com" target="_blank">jihan.duerme@gmail.com</a>
            </section>
        </main>
    );
};

export default About;