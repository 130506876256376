function Education() {
    return (
        <section className="about-content">
            <h3 className="mt-3">Education</h3>
            <section className="education">
                <div className="education-details align-items-start">
                    <div className="content-name">
                        <p className="m-0">Georgian College</p>
                        <p className="m-0">Interactive Media Design-Web</p>
                    </div>
                    <p className="content-info mb-0">
                        <span><small>Honour Roll<br />Dean's List</small></span>
                        <span><small>Honour Roll, Dean's List</small></span>
                    </p>
                </div>
                <div className="education-details align-items-start">
                    <div className="content-name">
                        <p className="mb-0">University of Santo Tomas</p>
                        <p className="mb-0">BFA Advertising Arts</p>
                    </div>
                    <p className="content-info mb-0">
                        <span><small>Best Thesis<br />Dean's List</small></span>
                        <span><small>Best Thesis, Dean's List</small></span>
                    </p>
                </div>
            </section>
        </section>
    );
};

export default Education;